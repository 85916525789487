import React from "react"
import { rem } from "polished"
import styled from "styled-components"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Button from "../components/button"
import Spacer from "../components/spacer"
import Container from "../components/container"

const Wrapper = styled.div`
  height: 100vh;
  color: ${(props) => props.theme.colorBlack};
  background: ${(props) => props.theme.colorSand};
  display: flex;
`

const Heading = styled.h1.attrs({ className: `styled-h2` })`
  text-align: center;
  @media ${(props) => props.theme.mediumUp} {
    font-size: ${rem(40)};
  }
  @media ${(props) => props.theme.largeUp} {
    font-size: ${rem(56)};
  }
`

const StyledContainer = styled(Container)`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

class NotFoundPage extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`dark`)
  }

  componentWillUnmount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Page not found" />

        <Wrapper>
          <StyledContainer>
            <Spacer bottom>
              <Heading>{`Sorry, the page you requested was not found.`}</Heading>
            </Spacer>
            <Button to="/">{`Back to home`}</Button>
          </StyledContainer>
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default NotFoundPage
