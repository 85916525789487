import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Arrow from "./svg/arrow"

import Link from "./link"

const StyledArrow = styled((props) => <Arrow {...props} />)``

const Text = styled.div.attrs({ className: `styled-label` })`
  margin-top: ${rem(10)};
`

const Container = styled.div`
  border: 1px solid currentColor;
  border-radius: 999em;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  color: currentColor;

  @media ${(props) => props.theme.smallUp} {
    padding: ${rem(20)} ${rem(60)} ${rem(16)} ${rem(60)};
  }

  @media ${(props) => props.theme.smallDown} {
    padding: ${rem(10)} ${rem(30)} ${rem(10)} ${rem(30)};
  }

  ${StyledArrow} {
    transform: translateX(0);
    transition: 0.3s;
    color: inherit;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media ${(props) => props.theme.smallDown} {
      width: ${rem(46)};
      height: ${rem(12)};
    }
  }

  &:hover {
    ${StyledArrow} {
      transform: translateX(6px);
    }
  }
`

const Button = ({ children, to, ...rest }) => {
  return (
    <Container as={to ? Link : undefined} to={to} {...rest}>
      <StyledArrow width={60} />
      <Text>{children}</Text>
    </Container>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  to: PropTypes.string,
}

export default Button
